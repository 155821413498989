<template>
  <div>
    <div class="container">
      <div class="searchForm">
        <el-form ref="form" :model="form" :inline="true" label-position="left" class="form">
          <el-form-item label="配送单号" label-width="70px">
            <el-input type="number" class="inputStyle" clearable v-model="form.skOrderId"></el-input>
          </el-form-item>
          <el-form-item label="订单号" label-width="70px">
            <el-input type="number" class="inputStyle" clearable v-model="form.deliveryId"></el-input>
          </el-form-item>
          <el-form-item label="订单类型" label-width="80px">
            <el-select v-model="form.orderSubType" clearable placeholder="请选择">
              <el-option v-for="item in orderTypeList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单状态" label-width="80px">
            <el-select v-model="form.state" clearable placeholder="请选择">
              <el-option v-for="item in orderStatusList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="timeAndBtn flexbox flex-lr flex-align-center">
          <div class="timeChoose">时间筛选</div>
          <el-date-picker v-model="time" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" :picker-options="pickerOptions">
          </el-date-picker>
          <el-button type="primary" class="sure" @click="screen">筛选</el-button>
          <el-button type="info" class="sure" @click="clear">清空</el-button>
          <PrevReclick>
            <!-- <div @click="exportExcle">导出数据</div> -->
            <el-button type="primary" class="sure" @click="excleVisible=true">导出数据</el-button>
          </PrevReclick>

        </div>
      </div>
      <div class="data-box flexbox flex-align-center">
        <div class="data-item pointer" style="flex:1;text-align:right">
          <img class="question" src="/img/order/explain.png" alt="" @click="showTips">
        </div>
        <div class="data-item">
          <div>{{orderNum.todayOrders || 0}}</div>
          <div>今日单数</div>
        </div>
        <div class="data-item">
          <div>{{totalOrderNum || 0}}</div>
          <div>总单数</div>
        </div>
        <div class="data-item">
          <div>{{orderNum.todayOrdersMoney || 0}}</div>
          <div>今日订单流水（元）</div>
        </div>
        <!-- <div class="data-item" @click="openRiderDialog">
          <div>{{orderNum.totalRiderAward || 0}}</div>
          <div class="pointer">骑手奖励金额&ensp;<span style="font-size:18px;vertical-align:-2px">>>></span></div>
        </div> -->
        <div class="data-item">
          <div>{{orderNum.todayMyProfit || 0}}</div>
          <div>今日我的分润（元）</div>
        </div>
        <div class="data-item">
          <div>{{orderNum.accumulateMyProfit || 0}}</div>
          <div>累计我的分润（元）</div>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="orderSubType" label="询价渠道" width="100">
          <template slot-scope="scope">
            <span>{{scope.row.accessSubId === 1 || scope.row.accessSubId===10000?'平台':scope.row.accessSubName||'-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderSubType" label="订单类型" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.orderType === 2">{{typeDateLists[scope.row.orderSubType] || '-'}}</span>
          </template>
        </el-table-column>
        
         <el-table-column prop="deliveryId" label="订单号"></el-table-column>
        <el-table-column prop="skOrderId" label="配送单号"></el-table-column>
       
        <el-table-column prop="createTime" label="订单创建时间" width="160"></el-table-column>
        <el-table-column label="订单结束时间" width="160">
          <template slot-scope="scope">
            <span>{{scope.row.realArriveTime || '—'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户支付商品价格" width="140">
          <template slot-scope="scope">
            <span>{{scope.row.goodsPrice || '—'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="distributeMoney" label="用户支付服务费" width="140"></el-table-column>
        <el-table-column label="骑手本单收入" width="140">
          <template slot-scope="scope">
            <span>{{scope.row.profitMoney || '—'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="我的分润" width="140">
          <template slot-scope="scope">
            <span>{{scope.row.agentProfit || '—'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="state" label="订单状态" width="140">
          <template slot-scope="scope">
            <span>{{orderStatusLists[scope.row.state||7].label || '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" align="center" label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="orderDetail(scope.row)">订单详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
      </el-pagination>
    </div>
    <Dialog ref="orderDialog"></Dialog>
    <!-- 订单详情弹窗 -->
    <runOrderDetail ref="runOrderDetail"></runOrderDetail>
    <!-- 导出弹窗 -->
    <el-dialog title="导出数据" :visible.sync="excleVisible" width="400px" >
      <span style="padding-right:10px">选择日期：</span>
      <el-date-picker v-model="excleTime"  type="daterange" range-separator="至" start-placeholder="开始日期"
        end-placeholder="结束日期" :picker-options="pickerOptions" >
      </el-date-picker>
      <p style="padding-top:20px;color:red">注：不能跨月导出数据</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="excleVisible = false">取 消</el-button>
         <PrevReclick>
        <el-button type="primary" @click="exportExcle">确认导出</el-button>
         </PrevReclick>
      </span>
    </el-dialog>
    <!-- 骑手奖金 -->
   <rider-money-detail ref='riderPage'/>
  </div>
</template>

<script>
import runOrderDetail from '@/components/order/runOrderDetail.vue';
import { $sg_errandOrdersProfit, $sg_pageErrandOrder } from '@/api/order';
import Dialog from '@/components/common/dialog.vue';
// eslint-disable-next-line no-unused-vars
import { exportFileSync } from '@base/utils';
import riderMoneyDetail from './components/riderMoneyDetail.vue';

export default {
  name: 'orderIndex',
  data() {
    return {
      form: {
        orderType: 2,
        skOrderId: '',
        orderSubType: '',
        state: '',
        deliveryId: '',
      },
      time: '',
      pickerOptions: {
        disabledDate(time) {
          // 获取2020年12月31日的时间戳
          const startTime = new Date('2020-12-31').getTime();
          // 获取目前时间的时间戳
          const endTime = new Date().getTime();
          // 禁止选择小于2021年1月1日之前和当前日期之后的日期
          return time.getTime() < startTime || time.getTime() > endTime;
        },
      },
      typeDateLists: ['', '帮取', '帮送', '帮买', '', '', '帮排队', '全能帮手'],
      orderTypeList: [
        { value: '', label: '全部' },
        { value: 1, label: '帮取' },
        { value: 2, label: '帮送' },
        { value: 3, label: '帮买' },
        { value: 6, label: '帮排队' },
        { value: 7, label: '全能帮手' },
      ],
      // 1待接单 2骑手已接单 3已到服务/取货地址 4进行中   5已完成 6已取消
      orderStatusList: [
        { value: '', label: '全部' },
        // { value: 1, label: '骑手待接单' },
        { value: 2, label: '已接单' },
        { value: 3, label: '已到服务/取货地址' },
        { value: 4, label: '进行中' },
        { value: 5, label: '已完成' },
        { value: 6, label: '已取消' },
       
      ],
      orderStatusLists: [
        { value: '', label: '全部' },
        { value: 1, label: '骑手待接单' },
        { value: 2, label: '已接单' },
        { value: 3, label: '已到服务/取货地址' },
        { value: 4, label: '进行中' },
        { value: 5, label: '已完成' },
        { value: 6, label: '已取消' },
        { value: 7, label: '-' },
      ],
      orderNum: {},
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      excleVisible: false,
      excleTime: null,
      riderMoneyDetailStatus: null,
      totalOrderNum: '',
    };
  },
  components: {
    runOrderDetail,
    Dialog,
    riderMoneyDetail,
  },
  created() {
    this.getOrderNum();
    this.screen();
  },
  methods: {
    openRiderDialog() {
      this.$refs.riderPage.riderMoneyVisible = true;
    },
    // 导出数据
    exportExcle() {
      let startTime = null;
      let endTime = null;
      if (this.excleTime) {
        console.log(this.excleTime, '34566');
        startTime = this.excleTime[0];
        endTime = this.excleTime[1];
        if (startTime.getMonth() !== endTime.getMonth() || startTime.getFullYear() !== endTime.getFullYear()) {
          this.$message.error('不能跨月导出数据');
          return;
        } else {
          startTime = (this.excleTime[0]).getTime() / 1000 || null; 
          endTime = (this.excleTime[1]).getTime() / 1000 || null;
        }
      } else {
        this.$message.error('请选择时间');
        return;
      }
      
      exportFileSync(
        '/j/distribution/agent/exportOrders/legOrders',
        {
          startTime,
          endTime,
        },
        'post',
        '跑腿订单.xlsx',
        this.onDownloadProgress,
      ).then(() => {
        this.excleVisible = false;
      });
    },
    showTips() {
      this.$refs.orderDialog.dialogs = {
        icon: '/img/dialog/icon-order.png',
        title2: '数据说明',
        content2: [
          {
            list: ['除总单数外，所有数据为统计总数据，不随筛选条件变动',
            ],
          },
        ],
        done: '已知晓',
      };
      this.$refs.orderDialog.showDialog = true;
    },
    // 订单详情
    orderDetail({ skOrderId, orderSubType }) {
      this.$refs.runOrderDetail.open(skOrderId, orderSubType);
    },
    // 查询订单数，分润
    getOrderNum() {
      $sg_errandOrdersProfit().then((res) => {
        this.orderNum = res;
      });
    },
    // 查询列表数据
    getList() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 10,
      };
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] || this.form[key] === 0) {
          params[key] = this.form[key];
        }
      });
      if (this.time && this.time.length > 0) {
        params.startTime = this.time[0];
        params.endTime = this.time[1];
      }
      $sg_pageErrandOrder(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
        this.totalOrderNum = res.total;
      });
    },
    // 翻页
    pageChange() {
      this.getList();
    },
    // 筛选
    screen() {
      this.currentPage = 1;
      this.getList();
    },
    // 清空
    clear() {
      this.form = {
        orderType: 2,
        skOrderId: '',
        orderSubType: '',
        state: '',
        deliveryId: '',
      };
      this.time = '';
      this.currentPage = 1;
      this.getList();
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  min-width: 1400px;
  min-height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
}
.el-form--inline .el-form-item {
  margin-right: 60px;
}
.inputStyle {
  // width: 180px;
}
.timeChoose {
  width: 80px;
  text-align: left;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}
.sure {
  margin-left: 30px;
}
.data-box {
  width: 100%;
  height: 90px;
  margin: 10px 0px;
  background-color: #f0f0f0;
  .data-item {
    flex: 6;
    div {
      width: 100%;
      text-align: center;
      margin-top: 5px;
    }
  }
}
.el-table {
  min-height: 580px;
  & > th {
    background-color: #ef3f46;
    color: #fff;
  }
}
</style>
