<template>
  <div class="orderDetailDialog">
    <el-dialog title="订单详情" :visible.sync="dialogVisible" width="1000px">
      <div v-loading="loading" element-loading-text="数据获取中...">
        <div class="flexbox">
          <div class="userInfo">
            <p>订单状态: {{orderStatusList[orderInfo.state]?orderStatusList[orderInfo.state].label:'-' || '-'}}</p>
            <p class="orderInfo-titel">用户信息</p>
            <!-- ? -->
            <!-- <p>微信号: XXXXXXXXXX</p> -->

            <p>手机号: {{orderInfo.acceptTel || '-'}}</p>
          </div>
          <div class="order-progress">
            <steps :stepList="stepList" :step="step" />
          </div>
        </div>
        <!-- 订单信息 -->
        <div class="info-lists">
          <p class="orderInfo-titel">订单信息</p>
          <div class="info-lists-content info-list-msg">
            <div>
              <p>订单号：{{orderInfo.deliveryId || '-'}}</p>
              <p>配送单号：{{orderInfo.skOrderId || '-'}}</p>
              <p>订单类型：{{orderTypeLists[orderType] || '-'}}</p>
              <!-- 帮我取 -->
              <div v-if="orderType === 1">
                <p>物品信息：{{baseData.goodsInfo || '-'}}</p>
                <p>物品重量：{{baseData.goodsWeight || 0}}kg</p>
                <p>收货密码：{{orderInfo.pickupCode || '-'}}</p>
                <p>取货时间：{{baseData.takeGoodsTime || '立即取货'}}</p>
                <p>{{baseData.needIncubator?'需要':'无需'}}保温箱配送</p>
                <p>订单备注：{{orderInfo.acceptDesc || '-'}}</p>
                <p>骑手备注：{{torderRider.remarks || '-'}}</p>

              </div>
              <!-- 帮我送 -->
              <div v-if="orderType === 2">
                <p>物品类型：{{baseData.goodsInfo || '-'}}</p>
                <p>物品重量：{{baseData.goodsWeight || 0}}kg</p>
                <p>收货密码：{{orderInfo.pickupCode || '-'}}</p>
                <p>{{baseData.needIncubator?'需要':'无需'}}保温箱配送</p>
                <p>订单备注：{{orderInfo.acceptDesc || '-'}}</p>
                <p>骑手备注：{{torderRider.remarks || '-'}}</p>
              </div>
              <!-- 帮我买 -->
              <div v-if="orderType === 3">
                <p>物品信息：{{baseData.goodsInfo || '-'}}</p>
                <p>物品重量：{{baseData.goodsWeight || 0}}kg</p>
                <p>收货密码：{{orderInfo.pickupCode || '-'}}</p>
                <p>{{baseData.needIncubator?'需要':'无需'}}保温箱配送</p>
                <p>购买时间：{{baseData.takeGoodsTime?baseData.takeGoodsTime:'立即购买'}}</p>
                <p>用户支付商品价格：{{baseData.goodsPrice || 0}}元</p>
                <p>骑手备注：{{torderRider.remarks || '-'}}</p>
              </div>
              <!-- 帮排队 -->
              <div v-if="orderType === 6">
                <p>排队内容备注：{{baseData.description || '-'}}</p>
                <p>开始时间：{{baseData.lineStartTime || '立即前往'}}</p>
                <p>排队时长：{{baseData.spendMinutes / 60 || 0}}小时</p>
                <p>收货密码：{{orderInfo.pickupCode || '-'}}</p>
                <p>骑手备注：{{torderRider.remarks || '-'}}</p>
              </div>
              <!-- 全能帮手 -->
              <div v-if="orderType === 7">
                <p>帮办内容：{{baseData.description || '-'}}</p>
                <p>开始时间：{{baseData.workStartTime || '立即前往'}}</p>
                <p>服务时长：{{baseData.spendMinutes / 60 || 0}}小时</p>
                <p>收货密码：{{orderInfo.pickupCode || '-'}}</p>
                <p>骑手备注：{{torderRider.remarks || '-'}}</p>
              </div>
            </div>
            <div>
              <p>下单时间：{{orderInfo.orderRentTime || '-'}}</p>

              <p>结束时间：{{orderInfo.realArriveTime || '-'}}</p>
              <p>用户支付服务费：{{orderInfo.distributeMoney || 0}}元</p>
              <p>用户支付爽约金：{{appointMoney || 0}}元</p>
              <p>骑手本单收入：{{tagentProfitLog.riderProfit || 0}}元</p>
              <p>我的分润：{{tagentProfitLog.agentProfit || 0}}元</p>
              
            </div>
          </div>
        </div>
        <div class="info-lists">
          <p class="orderInfo-titel">快递信息</p>
          <ul>
            <template  v-if="orderBaseDetail.helpTake">
            <template v-if="orderBaseDetail.helpTake.expressInfo">
              <li v-for="(item,index) in orderBaseDetail.helpTake.expressInfo" :key='index' class="send-infor-list">
                <span>{{item.name}}</span><span>{{item.address}}</span><span>{{item.code}}</span>
              </li>
            </template>
            </template>
          </ul>
        </div>
        <div class="info-lists" v-if="orderType <= 3">
          <p class="orderInfo-titel" v-if="orderType <= 2">寄件人信息</p>
          <p class="orderInfo-titel" v-else>购买信息</p>
          <div class="info-list-msg">
            <p class="fcb">
              <span>寄件人姓名：{{orderInfo.merchantName || '-'}}</span>
              <span>联系方式：{{orderInfo.merchantTel || '-'}}</span>
            </p>
            <p>寄件地址：{{orderInfo.merchantAddress || '-'}}</p>
          </div>
        </div>
        <!-- 1帮我取 2帮我送 3帮我买 4跑腿 5大件业务 6帮排队 7全能帮手 -->
        <div class="info-lists">
          <p class="orderInfo-titel" v-if="orderType === 1 || orderType === 3">收货信息</p>
          <p class="orderInfo-titel" v-if="orderType === 2">收件人信息</p>
          <p class="orderInfo-titel" v-if="orderType === 6">排队地址信息</p>
          <p class="orderInfo-titel" v-if="orderType === 7">帮办地址信息</p>
          <div class="info-list-msg">
            <p class="fcb">
              <span>
                <span v-if="orderType <=3">收件人姓名：</span>
                <span v-if="orderType >= 6">联系人姓名：</span>
                <span>{{orderInfo.acceptName || '-'}}</span>
              </span>
              <span>联系方式：{{orderInfo.acceptTel || '-'}}</span>
            </p>
            <p>
              <span v-if="orderType <=3">收件地址：</span>
              <span v-if="orderType === 6">排队地址：</span>
              <span v-if="orderType === 7">地址：</span>
              <span>{{orderInfo.acceptAddress || '-'}}</span>
            </p>
          </div>
        </div>
        <!-- 骑手信息 -->
        <!-- <div class="info-lists">
          <p class="orderInfo-titel">骑手信息</p>
          <div class="info-list-msg">
            <p class="fcb">
              <span>骑手姓名：{{orderInfo.riderName || '-'}}</span>
              <span>联系方式：{{orderInfo.riderPhone || '-'}}</span>
            </p>
          </div>
        </div> -->
      </div>

      <div slot="footer"></div>
        <template v-if="torderRider">
        <h5>配送信息：</h5>
        <div class="line-split-dom"></div>
        <div class="send-to-detail">
          <!-- <div class="send-log">
            <div class="send-log-text">转派订单</div>
          </div> -->
          <ul class="turn-send-list">
            <li >
              <div>
                <h4>{{torderRider.riderName}} {{torderRider.riderPhone}}</h4>
                <p><span class="process-label">{{torderRider.state==10?"被接单":'接单'}}</span> <span>{{torderRider.receiveTime}}</span></p>
                <p><span class="process-label">到达商家位置</span> <span>{{torderRider.takeTime}}</span></p>
                <p><span class="process-label">开始配送</span> <span>{{torderRider.distributionTime}}</span></p>
                <p><span class="process-label">到达送货地址</span> <span>{{torderRider.arriveTime}}</span></p>
              </div>
             
            </li>

          </ul>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import steps from '@/components/common/Steps.vue';
import { $sg_getRunLegOrderInfo } from '@/api/order';

export default {
  data() {
    return {
      orderType: null, // 订单类型
      dialogVisible: false,
      step: 0,
      loading: false,
      valueLabel: 'name',
      orderInfo: {}, // 订单基本信息
      baseData: {}, // 帮我取、帮我送、帮我买、帮排队、全能帮手
      tagentProfitLog: {}, // 分润信息
      torderRider: {},
      orderTypeLists: ['', '帮我取', '帮我送', '帮我买', '', '', '帮排队', '全能帮手'],
      orderStatusList: [
        { value: '', label: '全部' },
        { value: 1, label: '骑手待接单' },
        { value: 2, label: '已接单' },
        { value: 3, label: '已到服务/取货地址' },
        { value: 4, label: '进行中' },
        { value: 5, label: '已完成' },
        { value: 6, label: '已取消' },
      ],
      stepList: [
        {
          img: '/img/order/pay-money.png',
          activeImg: '/img/order/pay-money-active.png',
          name: '用户下单',
          step: 1,
        },
        {
          img: '/img/order/order.png',
          activeImg: '/img/order/order-active.png',
          name: '骑手待接单',
          step: 2,
        },
        {
          img: '/img/order/take-out-foods.png',
          activeImg: '/img/order/take-out-foods-active.png',
          name: '进行中',
          step: 3,
        },
        {
          img: '/img/order/finish.png',
          activeImg: '/img/order/finish-active.png',
          name: '完成',
          step: 4,
        },
      ],
      appointMoney: 0,
      orderBaseDetail: {
        helpTake: {
          expressInfo: [],
        },
      },
    };
  },
  components: {
    steps,
  },
  methods: {
    // 打开弹窗
    open(skOrderId, orderType) {
      this.loading = true;
      $sg_getRunLegOrderInfo({ skOrderId }).then((res) => {
        const {
          order, tagentProfitLog, torderRider,
        } = res;
        this.orderBaseDetail = res.orderBaseDetail;
        const keys = Object.keys(this.orderBaseDetail);
        keys.forEach((item) => {
          Object.assign(this.baseData, this.orderBaseDetail[item]);
        });
        this.appointMoney = res.appointMoney;
        // 0  1待接单 2骑手已接单 3已到服务/取货地址 4进行中   5已完成 6已取消
        const setpLists = [0, 2, 3, 3, 3, 4, 0];
        this.step = setpLists[order.state || 0];
        this.orderInfo = order || {};
        // this.runLegOrderInfo = runLegOrderInfo || {};
        this.tagentProfitLog = tagentProfitLog || {};
        this.torderRider = torderRider;
      }).finally(() => {
        this.loading = false;
      });
      this.orderType = orderType;
      this.dialogVisible = true;
    },
    resetData() {
      this.orderInfo = {};
      this.baseData = {};
      this.tagentProfitLog = {};
    },
  },
};
</script>
<style lang="scss">
@import "@/style/var.scss";
.fcb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.send-to-detail {
    position: relative;
    min-height: 100px;
    .send-log {
      position: absolute;
      top: 20px;
      right: 0px;
      border: 22px solid;
      height: 0px;
      width: 75px;
      border-color: red red red white;
      border-radius: 0px 10px 10px 0px;
      .send-log-text {
        color: white;
        margin: -10px 0px 0px 0px;
        padding-left: 18px;
      }
    }
    .turn-send-list {
      max-height: 300px;
      overflow-y: auto;
      li {
        line-height: 35px;
      }
      .process-label {
        display: inline-block;
        width: 110px;
      }
    }
  }
.orderDetailDialog {
  .el-dialog {
    border-radius: 6px;
    .el-dialog__header {
      padding: 10px 35px;
      border-bottom: 1px solid #ececec;
      background: #f8f7f7;
      border-radius: 6px 6px 0 0;
      .el-dialog__title {
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
        color: #333333;
      }
      .el-dialog__headerbtn {
        top: 14px;
        right: 20px;
      }
    }
    .el-dialog__body {
      padding: 18px 35px 28px;
    }
    .el-dialog__footer {
      text-align: center;
      padding: 0;
      &:after {
        display: block;
        content: "";
        height: 20px;
        border-top: 1px solid #ececec;
        background: #f8f7f7;
        border-radius: 0 0 6px 6px;
      }
    }
  }
  .userInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30%;
    margin-right: 14px;
    min-height: 100px;
    border: 1px solid #ececec;
    box-sizing: border-box;
    padding: 14px 10px;
    border-radius: 6px;
  }
  .order-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(70% - 14px);
  }
  .send-infor-list{
line-height: 35px;
    display: flex;
    width: 30%;
    flex-flow: wrap;
    justify-content: space-between;
  }
  .orderInfo-titel {
    font-size: 14px;
    color: #333;
    font-weight: bold;
  }
  .info-lists {
    margin-top: 20px;
    .orderInfo-titel {
      padding-bottom: 10px;
      border-bottom: 1px solid #dbdbdb;
    }
    .info-lists-content {
      width: 100%;
      display: flex;
      justify-content: space-between;
      > div {
        width: 50%;
      }
    }
    .info-list-msg {
      p {
        margin-top: 6px;
      }
    }
  }
}
</style>
