<!--  -->
<template>
  <div>
    <el-dialog :visible.sync="riderMoneyVisible" width="700px">
      <div class="flexbox flex-lr flex-align-center">
        <el-input v-model="riderNameOrPhone" placeholder="输入骑手名字或电话" style="width:200px;" clearable></el-input>
        <el-date-picker v-model="riderTime" class="ml20 mr20" type="daterange" range-separator="至" start-placeholder="开始时间"
          value-format='yyyy-MM-dd' end-placeholder="结束时间" :picker-options="pickerOptions">
        </el-date-picker>
        <el-button type="primary" @click="searchRiderTable">筛选</el-button>
        <el-button type="info" @click="clearRiderTable">清空</el-button>

      </div>
      <p style="padding:10px 0">累计奖励骑手金额：{{totalMoney}}元</p>
      <el-table :data="tableData" style="width: 100%" class="mt10" border>
        <el-table-column prop="riderName" label="骑手姓名"></el-table-column>
        <el-table-column prop="riderPhone" label="电话"></el-table-column>
        <el-table-column label="奖励时间">
          <template slot-scope="scope">
            <span>{{scope.row.awardTime || '—'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="awardMoney" label="奖励金额"></el-table-column>
      </el-table>
      <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
      </el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { $sg_getRiderAwardList, $sg_getTotalRiderAward } from '@/api/order';

export default {
  data() {
    return {
      riderNameOrPhone: null,
      riderMoneyVisible: false,
      riderTime: null,
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      totalMoney: null,
      pickerOptions: {
        disabledDate(time) {
          // 获取2020年12月31日的时间戳
          const startTime = new Date('2020-12-31').getTime();
          // 获取目前时间的时间戳
          const endTime = new Date().getTime();
          // 禁止选择小于2021年1月1日之前和当前日期之后的日期
          return time.getTime() < startTime || time.getTime() > endTime;
        },
      },
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.getRiderAwardList();
  },

  methods: {

    searchRiderTable() {
      this.getRiderAwardList();
    },
    clearRiderTable() {
      this.riderTime = null;
      this.riderNameOrPhone = null;
      this.getRiderAwardList();
    },

    getRiderAwardList() {
      let startTime; let endTime;
      if (this.riderTime) {
        startTime = this.riderTime[0];
        endTime = this.riderTime[1];
      }
      const param = {
        pageCurrent: this.currentPage, pageSize: 8, startTime, endTime, riderNameOrPhone: this.riderNameOrPhone, 
      };
      $sg_getRiderAwardList(param).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
      $sg_getTotalRiderAward(param).then((res) => {
        this.totalMoney = res;
      });
    },
    pageChange() {
      this.getRiderAwardList();
    },
  },
};

</script>
<style lang='scss' scoped>
</style>
